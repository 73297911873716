.message-box{
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.offset-top{
    margin-top: 30vh;
}

.link{
    color: rgba(0, 0, 0, 0.948);
}